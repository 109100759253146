<template>
  <div
    class="flex max-w-72 shrink-0 flex-col items-start px-3.5 justify-between w-full h-full divide-y"
  >
    <div
      v-if="!addingStep"
      class="w-full flex justify-around flex-col py-m gap-4"
    >
      <UInput
        v-model="searchQuery"
        icon="i-ph-magnifying-glass-bold"
        placeholder="Search for any step"
        color="white"
        class="transition-all w-full"
        size="xl"
        :trailing="false"
        :ui="{
          leading: {
            padding: {
              xl: 'ps-[3.2rem]',
            },
          },
          icon: {
            leading: {
              padding: {
                xl: 'px-3.5',
              },
            },
          },
          padding: {
            xl: 'py-3 px-0',
          },
        }"
      />

      <UButton
        variant="soft"
        size="xl"
        color="gray"
        block
        :ui="{
          rounded: 'rounded-lg',
          padding: {
            xl: 'py-6 px-3',
          },
        }"
        class="border border-gray-300"
        to="/templates"
      >
        <UIcon
          name="i-ph-stack-fill"
          class="transition-all text-2xl text-gray-600"
          :class="{ '-translate-x-12': isExpanded }"
        />
        <transition name="fade" class="absolute left-28">
          <p v-if="isExpanded" class="body dimmed">Templates</p>
        </transition>
      </UButton>
    </div>

    <UVerticalNavigation
      :links="categoryOptions"
      class="shrink-0 py-m"
      :class="{
        'pt-0': addingStep,
      }"
      :ui="{
        ...verticalNavStyles,
        base: 'items-start mx-0 gap-6 shrink-0 w-full text-nowrap py-4 my-1',
        icon: {
          base: 'ml-0.5',
        },
      }"
    >
      <template #icon="{ link }">
        <UIcon :name="link.icon" class="w-6 h-6 z-50 shrink-0" />
      </template>
    </UVerticalNavigation>

    <UVerticalNavigation
      :links="helpOptions"
      class="shrink-0 py-l"
      :ui="{
        ...verticalNavStyles,
        base: 'items-start mx-0 gap-6 shrink-0 w-full text-nowrap py-0 my-0',
        icon: {
          base: 'w-5 h-5 ml-1',
        },
        size: 'text-sm',
      }"
    />
  </div>
</template>
<script setup lang="ts">
import { categories } from '@respell/steps';
import { isDev } from '@respell/utils';
import { useVueFlow } from '@vue-flow/core';
import type { StepType } from '~/stores/canvas';
import { allType } from '~/stores/canvas';

const verticalNavStyles = {
  wrapper: 'shrink-0 w-full',
  active: 'before:bg-primary-200',
};

const startChat = () => {
  if (import.meta.client) {
    window.$unthread.inAppChat('toggle', 'show');
  }
};

const helpOptions = [
  {
    label: 'Help & Resources',
    icon: 'i-ph-question-bold',
    iconClass: 'text-gray-300  group-hover:text-gray-500',
    labelClass: 'text-gray-300 group-hover:text-gray-500',
    to: 'https://docs.respell.ai',
    external: true,
  },
  {
    label: 'Request an integration',
    icon: 'i-ph-arrow-square-out-bold',
    iconClass: 'text-gray-300  group-hover:text-gray-500',
    labelClass: 'text-gray-300 group-hover:text-gray-500',
    click: isDev ? undefined : startChat,
  },
];

const { isExpanded } = defineProps({
  isExpanded: Boolean,
});

const canvasStore = useCanvasStore();
const { isShowingPanel, typeFilter, searchQuery } = storeToRefs(canvasStore);
const { addingStep } = useSelectedStep();

const categoryOptions = computed(() => {
  const stepTypes = addingStep.value ? [allType, ...categories] : categories;

  return stepTypes.map((type) => ({
    label: type.name,
    icon:
      typeFilter.value?.name === type.name
        ? getActiveIcon(type.icon)
        : type.icon,
    click: () => togglePanel(type),
    active: typeFilter.value?.name === type.name,
  }));
});

const { removeSelectedElements } = useVueFlow({
  id: 'editor',
});

const togglePanel = (type: StepType) => {
  if (typeFilter.value?.name === type.name) {
    typeFilter.value = allType;
    !addingStep.value && (isShowingPanel.value = false);
    removeSelectedElements();
  } else {
    typeFilter.value = type;
    !addingStep.value && (isShowingPanel.value = true);
  }
};

function getActiveIcon(icon: string) {
  return icon.includes('-bold')
    ? icon.replace('-bold', '-fill')
    : icon + '-fill';
}
</script>
